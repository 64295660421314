const textData = {
	title: {
		ru:"Ой, что-то пошло не так !",
		en:"Oops, something went wrong !",
	},
	p1:{
		ru:"К сожалению, произошла ошибка при загрузке данных. Пожалуйста, попробуйте обновить страницу.",
		en:"Sorry, there was an error loading the data. Please try refreshing the page.",
	},
	p2:{
		ru:"Если проблема сохраняется, свяжитесь со мной.",
		en:" If the issue persists, feel free to reach out to me."
	},
	button: {
		ru:"Связаться со мной",
		en:"Contact me",
	}
};

export default textData;