import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";

import "./lang-switcher.sass";

function LangSwitcher() {
	const { lang, theme, setLang } = useContext(AppData);
	const switchLang = () => (lang === "ru" ? setLang("en") : setLang("ru"));

	return (
		<button
			onClick={switchLang}
			className="lang-switcher"
			data-theme={theme}
			data-lang={lang}
		>
			<div>
				<span className="eng">ENG</span>
				<span className="rus">РУС</span>
			</div>
		</button>
	);
}

export default LangSwitcher;
