import IInput from "../interfaces/IInput";

const inputs: IInput[] = [
    {
      name: "name" as const,
      validation: { required: true, minLength: 2, pattern: /^[А-яЁё A-Za-z]+$/i },
    },
    {
      name: "email" as const,
      validation: {
        required: true,
        pattern: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
      },
    },
    {
      name: "message" as const,
      validation: { required: true },
    },
  ];

export default inputs;