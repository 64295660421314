import React, { useEffect } from "react";
import SimpleBar from 'simplebar-react';
import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";

import 'simplebar-react/dist/simplebar.min.css';
import "./project-item-technologies.sass";

function changeScrollYonX (this:HTMLElement ,event: WheelEvent) {
	let modifier = 1;
	if (event.deltaMode === event.DOM_DELTA_PIXEL) {
			modifier = 1;
			// иные режимы возможны в Firefox
	} else if (event.deltaMode === event.DOM_DELTA_LINE) {
			modifier = parseInt(getComputedStyle(this).lineHeight);
	} else if (event.deltaMode === event.DOM_DELTA_PAGE) {
			modifier = this.clientHeight;
	}
	if (event.deltaY !== 0) {
			// замена вертикальной прокрутки горизонтальной
			this.scrollLeft += modifier * event.deltaY;
			event.preventDefault();
	}}


function ProjectItemTechnologies({technologies}:{technologies:string[]}) {

	const { theme } = useContext(AppData);
	const scrollableNodeRef = React.createRef();

	useEffect(() => {
		const scrollElement = scrollableNodeRef.current as HTMLElement;
		const scrollContent = scrollElement.querySelector(
				".project-item-technologies__list"
		) as HTMLElement;

		const scrollElementWidth = parseInt(
				getComputedStyle(scrollElement).width,
				10
		);
		const scrollContentWidth = parseInt(
				getComputedStyle(scrollContent).width,
				10
		);

		if (scrollElementWidth < scrollContentWidth) {
				scrollElement.addEventListener("wheel", changeScrollYonX);
		}
		return () => {
			scrollElement.removeEventListener("wheel", changeScrollYonX);
		}
});

	return (
		<div className="project-item-technologies" data-theme={theme}>
			<div className="project-item-technologies__wrapper">
				<SimpleBar
				forceVisible="x"
				autoHide={false}
				scrollableNodeProps={{ ref: scrollableNodeRef }}
				>
				<div className="project-item-technologies__list">
				{technologies.map((tehnology, i) => (
					<div className="project-item-technologies__technology" key={i}>
						{tehnology}
					</div>
				))}
				</div>
			</SimpleBar>
			</div>

	</div>
	)
}

export default ProjectItemTechnologies