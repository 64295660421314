import { useState } from "react";

import IStorage from "../interfaces/IStorage";

const useStorage = ({
	key,
	initialValue,
	storage,
	possibleValues,
}: IStorage) => {
	const usedStorage =
		storage === "localStorage" ? localStorage : sessionStorage;
	const [storedValue, setStoredValue] = useState(() => {
		if (typeof window === "undefined") {
			return initialValue;
		}
		try {
			const item: string | null = usedStorage.getItem(key);

			const parsedItem = item === null ? "" : JSON.parse(item);

			if (
				Array.isArray(possibleValues) &&
				possibleValues.includes(parsedItem)
			) {
				return parsedItem;
			} else {
				return initialValue;
			}
		} catch (error) {
			console.log(error);
			return initialValue;
		}
	});

	const setValue = (value: string | number | boolean) => {
		try {
			setStoredValue(value);
			if (typeof window !== "undefined") {
				usedStorage.setItem(key, JSON.stringify(value));
			}
		} catch (error) {
			console.log(error);
		}
	};
	return [storedValue, setValue];
};

export default useStorage;
