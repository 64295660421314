import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";
import textData from "../../data/error-screen-text-data";
import "./error-screen.sass";

function ErrorScreen() {
	const { lang, theme } = useContext(AppData);
	const navigate = useNavigate();
    return (
        <div className="error-screen" data-theme={theme}>
            <div className="container">
                <h1>{textData.title[lang]}</h1>
                <p>{textData.p1[lang]}</p>
                <p>{textData.p2[lang]}</p>
								<button
								className="button button_error" 
								data-theme={theme}
								onClick={() => navigate("./contact")}
								>
									{textData.button[lang]}
								</button>
            </div>
        </div>
    );
}

export default ErrorScreen;