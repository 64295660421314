import "./text-skeleton.sass";

function TextSkeleton({rows, height, margin} : {rows:number, height?:number, margin?:number}) {

	const skeletonRows = []
	const rowStyle = {
		height: height + "px", 
		margin: margin + "px 0",
	}

	for (let i = 0; i < rows; i++) {
    skeletonRows.push(<div style={rowStyle} key={i} className="skeleton-item"></div>);
  }

return (
	<div className="text-skeleton">
		{skeletonRows}
	</div>
)
}

export default TextSkeleton;