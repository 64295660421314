import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/scroll-to-top/Scroll-to-top";
import AppProvider from "./components/app-provider/App-provider";
import { ToastContainer } from "react-toastify";

import App from "./components/app/App";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <AppProvider>
        <ToastContainer
          newestOnTop
          limit={1}
          closeButton={false}
          draggableDirection="y"
          draggablePercent={60}
        />
        <App />
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>
);
