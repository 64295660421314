function transformResponse(response: any) {

	if(response === null) {return null}
	
    const { data } = response;

		function getSingleText(name:string) {
			return data[name].data.attributes.value;
		}

		const projectItemsData = data.projectItems.data;
		const skillItemsData = data.skillItems.data;

		const aboutMeText = getSingleText("aboutMeText");
		const promoText = getSingleText("promoText");
		const secretMessage = getSingleText("secretMessage");

		const baseApiUrl = process.env.REACT_APP_API_BASE_URL as string;

    const projectItems = projectItemsData.map((item: any) => {
        const { title, description, github, link, imgs, technologies } =
            item.attributes;
        return {
            title,
            description,
            github,
            link,
            imgs: imgs.data.map((img: any) => baseApiUrl + img.attributes.url),
            technologies: technologies.map((obj: any) => obj.value),
        };
    });
		const skillItems = skillItemsData.map((item:any) => {
			const {name, icon} = item.attributes;
			return {
				name,
				icon: baseApiUrl + icon.data.attributes.url,
			}
		})

		console.log({egg:secretMessage});

		return {
			projectItems,
			aboutMeText,
			promoText,
			skillItems,
		}
}

export default transformResponse;
