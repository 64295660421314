import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";
import ISocialLink from "../../interfaces/ISocial-link";
import "./social-link.sass";

function SocialLink({ name, href, target, svg }: ISocialLink) {
		const { theme } = useContext(AppData);
		return (
				<a
						className="social-link"
						href={href}
						rel="noreferrer"
						target={target}
						data-theme={theme}
				>
						{svg}
						{name}
				</a>
		);
}

export default SocialLink;
