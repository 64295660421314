import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";
import ILangs from "../../interfaces/ILangs";

function ItemsListWrapper({clazz, title, addElem, children }:
	{clazz:string; title:ILangs;addElem?:React.ReactNode; children:React.ReactNode}) {
	const { theme, lang } = useContext(AppData);
	return (
		<section className={clazz + "-item-list"}>
				<div className="container">
						<h3 className="title" data-theme={theme}>
								{title[lang]}
						</h3>
						<div className={`${clazz}-item-list__content`}>
							{children}
						</div>
						{addElem}
				</div>
		</section>
);

}

export default ItemsListWrapper;