import ISocialLink from "../interfaces/ISocial-link";

const xmlns = "http://www.w3.org/2000/svg";
const gradient = (
	<linearGradient id="gradient">
		<stop className="stop" offset="0%" />
		<stop className="stop" offset="100%" />
	</linearGradient>
);

const socials: ISocialLink[] = [
	{
		name: "Gmail",
		href: "mailto:dev.iuriinikitin@gmail.com",
		target: "_self",
		svg: <svg xmlns={xmlns} viewBox="0 0 40 40"><circle cx="20" cy="20" r="20" className="color-main"/><path d="m31.29,13.18v14.4c0,.82-.62,1.44-1.44,1.44h-1.44v-13.06l-8.64,6.2-8.64-6.2v13.06h-1.44c-.82,0-1.44-.62-1.44-1.44v-14.4c0-.41.15-.77.41-1.02.13-.13.29-.24.47-.31.18-.07.37-.11.56-.1h.48l9.6,6.96,9.6-6.96h.48c.41,0,.77.15,1.03.42.26.25.41.61.41,1.02Z" className="color-second"/>{gradient}</svg>,
	},
	{
		name: "Telegram",
		href: "https://t.me/Y0uR11",
		target: "_blank",
		svg:<svg xmlns={xmlns} viewBox="0 0 40 40"><circle cx="20" cy="20" r="20" className="color-main"/><path d="m7.14,19.98c2.19-1.21,4.64-2.22,6.92-3.23,3.93-1.66,7.88-3.29,11.87-4.81.78-.26,2.17-.51,2.31.64-.07,1.63-.38,3.25-.59,4.86-.54,3.56-1.16,7.11-1.76,10.65-.21,1.18-1.69,1.79-2.64,1.04-2.28-1.54-4.57-3.06-6.82-4.63-.74-.75-.05-1.82.6-2.36,1.87-1.85,3.86-3.42,5.64-5.36.48-1.16-.94-.18-1.4.12-2.57,1.77-5.07,3.64-7.78,5.2-1.38.76-2.99.11-4.37-.31-1.24-.51-3.05-1.03-1.99-1.81h0Z" className="color-second"/></svg>,
	},
	{
		name: "Github",
		href: "https://github.com/IuriiNikitin",
		target: "_blank",
		svg: <svg xmlns={xmlns} viewBox="0 0 40 40"><circle cx="20" cy="20" r="20" className="color-second"/><path d="m20,0C8.95,0,0,8.95,0,20c0,8.85,5.72,16.32,13.67,18.98,1,.17,1.38-.43,1.38-.95,0-.47-.02-2.05-.02-3.72-5.03.92-6.33-1.23-6.73-2.35-.22-.57-1.2-2.35-2.05-2.83-.7-.37-1.7-1.3-.02-1.32,1.57-.03,2.7,1.45,3.08,2.05,1.8,3.03,4.68,2.18,5.83,1.65.17-1.3.7-2.18,1.27-2.68-4.45-.5-9.1-2.22-9.1-9.87,0-2.17.77-3.98,2.05-5.38-.2-.5-.9-2.55.2-5.3,0,0,1.67-.53,5.5,2.05,1.6-.45,3.3-.68,5-.68s3.4.23,5,.68c3.82-2.6,5.5-2.05,5.5-2.05,1.1,2.75.4,4.8.2,5.3,1.28,1.4,2.05,3.17,2.05,5.38,0,7.67-4.67,9.38-9.12,9.87.72.62,1.35,1.83,1.35,3.7,0,2.67-.03,4.82-.03,5.5,0,.53.37,1.15,1.38.95,7.9-2.65,13.62-10.15,13.62-18.98C40,8.95,31.05,0,20,0Z" className="color-main"/></svg>,
	},
];

export default socials;