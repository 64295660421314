import { useState } from "react";
import { NavLink } from "react-router-dom";
import Hamburger from "../hamburger/Hamburger";
import ThemeSwitcher from "../theme-switcher/Theme-switcher";
import LangSwitcher from "../lang-switcher/Lang-switcher";
import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";
import textData from "../../data/links-text-data";

import "./header.sass";

function Header() {
	const [isActive, setIsActive] = useState(false);
	let menuClazz = isActive ? "menu menu_active" : "menu";
	const toggleMenu = () => {
		isActive ? setIsActive(false) : setIsActive(true);
	};
	const { lang, theme } = useContext(AppData);
	return (
		<header className="header" data-theme={theme}>
			<nav className="nav">
				<div className="container">
					<div className="nav__wrapper">
						<div className="nav__btns">
							<div>
								<NavLink to="/" className="nav-link">
									{textData.home[lang]}
								</NavLink>
							</div>
							<ThemeSwitcher />
							<LangSwitcher />
						</div>
						<ul className={menuClazz}>
							<li onClick={() => setIsActive(false)} className="menu__item">
								<NavLink to="/" className="nav-link">
									{textData.home[lang]}
								</NavLink>
							</li>
							<li onClick={() => setIsActive(false)} className="menu__item">
								<NavLink to="/projects" className="nav-link">
									{textData.projects[lang]}
								</NavLink>
							</li>
							<li onClick={() => setIsActive(false)} className="menu__item">
								<NavLink to="/contact" className="nav-link">
									{textData.contact[lang]}
								</NavLink>
							</li>
						</ul>
					</div>
					<Hamburger isActive={isActive} toggleMenu={toggleMenu} />
				</div>
			</nav>
		</header>
	);
}

export default Header;
