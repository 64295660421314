function GithubIcon(props: { height: string }) {
  return (
    <svg
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="m10,0C4.47,0,0,4.47,0,10c0,4.42,2.86,8.16,6.84,9.49.5.09.69-.21.69-.48,0-.24-.01-1.02-.01-1.86-2.51.46-3.16-.61-3.36-1.18-.11-.29-.6-1.17-1.02-1.41-.35-.19-.85-.65-.01-.66.79-.01,1.35.72,1.54,1.02.9,1.51,2.34,1.09,2.91.83.09-.65.35-1.09.64-1.34-2.22-.25-4.55-1.11-4.55-4.94,0-1.09.39-1.99,1.02-2.69-.1-.25-.45-1.27.1-2.65,0,0,.84-.26,2.75,1.03.8-.23,1.65-.34,2.5-.34s1.7.11,2.5.34c1.91-1.3,2.75-1.03,2.75-1.03.55,1.37.2,2.4.1,2.65.64.7,1.02,1.59,1.02,2.69,0,3.84-2.34,4.69-4.56,4.94.36.31.67.91.67,1.85,0,1.34-.01,2.41-.01,2.75,0,.26.19.58.69.48,3.95-1.33,6.81-5.08,6.81-9.49C20,4.47,15.53,0,10,0Z"
        fill="#dbffff"
      />
    </svg>
  );
}

export default GithubIcon;
