import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";
import textData from "../../data/links-text-data";
import socials from "../../data/social-links-data";
import SocialLink from "../social-link/Social-link";

import "./footer.sass";

const year = new Date().getFullYear();

function Footer() {
		const { lang, theme } = useContext(AppData);
		return (
				<footer className="footer" data-theme={theme}>
						<div className="container">
								<div className="footer__contacts">
										{socials.map(({name, href, target, svg}, i) => (
												<SocialLink
														name={name}
														href={href}
														target={target}
														svg={svg}
														key={i}
												/>
										))}
								</div>
								<div className="footer__links">
										<Link to={"/projects"}>{textData.projects[lang]}</Link>
										<Link to={"/contact"}>{textData.contact[lang]}</Link>
								</div>
								<div className="footer__signature">
										Software Developer {year}
								</div>
						</div>
				</footer>
		);
}

export default Footer;
