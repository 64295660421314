import { useContext } from "react";
import { AppData } from "../../app-provider/App-provider";
import TextSkeleton from "../text-skeleton/Text-skeleton";

import "./project-item-skeleton.sass"

function ProjectItemSkeleton() {
	const { theme } = useContext(AppData);

	return (
		<div className="project-item-skeleton" data-theme={theme}>
			<div className="project-item-skeleton__header">
			</div>
			<div className="project-item-skeleton__wrapper">
				<div className="project-item-skeleton__title"></div>
				<div className="project-item-skeleton__technologies"></div>

				<TextSkeleton rows={5} height={14}/>

				<div className="project-item-skeleton__links">

				<div className="project-item-skeleton__links">
					<div className="project-item-skeleton__link"></div>
					<div className="project-item-skeleton__link"></div>
				</div>
				</div>
			</div>
		</div>
	);
}

export default ProjectItemSkeleton;