import Promo from "../components/promo/Promo";
import AboutMe from "../components/about-me/About-me";
import ProjectItemList from "../components/project-item-list/Project-item-list";
import SkillItemList from "../components/skill-item-list/Skill-item-list";

import { useContext } from "react";
import { AppData } from "../components/app-provider/App-provider";

import { useNavigate } from "react-router-dom";

import textData from "../data/project-item-list-text-data";

const Home = () => {
	const navigate = useNavigate();
	const { lang, theme } = useContext(AppData);
	const button = (
        <button
            className="button button_projects"
            data-theme={theme}
            onClick={() => navigate("./projects")}
        >
            {textData.button[lang]}
        </button>
    );

	return (
		<>
			<Promo />
			<AboutMe />
			<ProjectItemList 
				reversed={true}
				viewItems={2}
				addElem={button}
				/>
			<SkillItemList/>
		</>
	);
};

export default Home;
