import ITextData from "../interfaces/IText-data";

const textData: ITextData = {
  home: {
    ru: "Главная",
    en: "Home",
  },
  projects: {
    ru: "Проекты",
    en: "Projects",
  },
  contact: {
    ru: "Связаться",
    en: "Contact",
  },
};

export default textData;
