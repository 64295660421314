import ITextData from "../interfaces/IText-data";

const textData: ITextData = {
  title: {
    ru: "Обо мне",
    en: "About me",
  }
};

export default textData;
