import ContactForm from "../components/contact-form/Contact-form";

const Contact = () => {
  return (
    <div className="container">
      <ContactForm />
    </div>
  );
};

export default Contact;
