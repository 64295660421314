import { useState, useEffect } from 'react';
import TResponseStatus from '../interfaces/TResponse-status';

interface Options {
  method: string;
  headers: { [key: string]: string };
  body: string;
}

interface ResponseData {
  data: unknown;
}

const useHttp = (url: string, options: Options) => {

	const [status, setStatus] = useState<TResponseStatus>("idle");
  const [data, setData] = useState<ResponseData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
			setStatus("loading");
      try {
        const response = await fetch(url, options);
        const responseData = await response.json();
        if (!response.ok) {
					setStatus("fail");
          throw new Error(responseData.message || 'Something went wrong');
        }
        setData(responseData);
				setStatus("success");
      } catch (error: unknown) {
				if (error instanceof Error) {
					setStatus("fail");
					setError(error.message || 'Something went wrong');
				}
      }
    };

    fetchData();
		
				// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return { data, status, error };
};

export default useHttp;

