import { useContext } from "react";
import textData from "../../data/project-item-list-text-data";
import ProjectItem from "../project-item/Project-item";
import ItemsList from "../items-list/Items-list";
import ItemsListWrapper from "../items-list-wrapper/Items-list-wrapper";
import { IOptions } from "../../interfaces/IItem-list";
import ProjectItemSkeleton from "../skeletons/project-item-skeleton/Project-item-skeleton";
import { AppData } from "../app-provider/App-provider";

import "./project-item-list.sass";



function ProjectItemList(options: IOptions) {

	const {data, responseStatus} = useContext(AppData);

	let content;

	switch(responseStatus) {
		case "loading":
			content = <><ProjectItemSkeleton/><ProjectItemSkeleton/></>
			break;
		case "success":
			content = <ItemsList data={data?.projectItems || null} Item={ProjectItem} options={options}/>
		break;
	}

	return (
		<ItemsListWrapper clazz="project" title={textData.title} addElem={options.addElem}>
			{content}
		</ItemsListWrapper>
)
}

export default ProjectItemList;