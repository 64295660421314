import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";
import ISkillItem from "../../interfaces/ISkill-item";

import "./skill-item.sass";

function SkillItem(props: ISkillItem) {
	
	const { theme } = useContext(AppData);
	const {name, icon} = props;

	return (
		<div className="skill-item" data-theme={theme}>
			<img
				className="skill-item__logo"
				src={icon}
				alt={name.toLocaleLowerCase() + "_logo"}
				id={name.toLocaleLowerCase() + "_logo"}
			/>
			{props.name}
		</div>
	);
}

export default SkillItem;
