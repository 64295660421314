import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";
import textData from "../../data/about-me-text-data";
import TextSkeleton from "../skeletons/text-skeleton/Text-skeleton";

import "./about-me.sass";

function AboutMe() {
	const { lang, theme, data, responseStatus } = useContext(AppData);

	let aboutMe;

	switch(responseStatus){
		case "loading":
			aboutMe = <TextSkeleton rows={5} height={14}/>
			break;
		case "success":
			aboutMe = data?.aboutMeText[lang];
			break;
	}
	return (
		<section className="about-me" data-theme={theme}>
			<div className="container">
				<div className="about-me__wrapper">
					<h3 className="title" data-theme={theme}>
						{textData.title[lang]}
					</h3>
					<div className="about-me__text">{aboutMe}</div>
				</div>
			</div>
		</section>
	);
}

export default AboutMe;
