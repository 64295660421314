import ITextData from "../interfaces/IText-data";

const textData: ITextData = {
  title: {
    ru: "Навыки",
    en: "Skills",
  },
};

export default textData;
