import { useState } from "react";
import IItemList from "../../interfaces/IItem-list";


function ItemsList<T>({ data, Item, options }: IItemList<T>) {

  const [viewItems] = useState(options?.viewItems || 0);
  const [reversed] = useState(options?.reversed || false);
 
  function transformData() {
    if (!data) return [];
    let newList = [...data];

		if (reversed) {
      newList.reverse();
    }

    if (viewItems) {
      const viewCount = Math.min(Math.max(1, viewItems), data.length);
      newList = newList.slice(0, viewCount);
    }

    return newList;
  }

  return (
	<>
			{transformData().map((item, i) => <Item key={i} {...item} />)}
	</>

	)
}

export default ItemsList;
