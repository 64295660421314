import { Routes, Route, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";

import "./main.sass";

import Home from "../../pages/home";
import Projects from "../../pages/projects";
import Contact from "../../pages/contact";
import ErrorScreen from "../error-screen/Error-screen";




const routes = [
	{ path: "/", elem: <Home /> },
	{ path: "/projects", elem: <Projects /> },
	{ path: "/contact", elem: <Contact /> },
	{ path: "*", elem: <Home /> },
];

function Main() {
	
	const locationPath = useLocation().pathname;

	if ( !routes.find(route => route.path === locationPath) ) {
		window.location.pathname = "";
	}

	const { theme, responseError} = useContext(AppData);

if (responseError) {
	return (
			<main className="main" data-theme={theme}>
					<Routes>
							<Route path="*" element={<ErrorScreen />} />
							<Route path="/contact" element={<Contact />} />
					</Routes>
			</main>
	);
}

return (
	<main className="main" data-theme={theme}>
			<Routes>
					{routes.map((route, i) => (
							<Route path={route.path} element={route.elem} key={i} />
					))}
			</Routes>
	</main>
);
}

export default Main;
