import Header from "../header/Header";
import Main from "../main/Main";
import Footer from "../footer/Footer";

import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";

import "./app.sass";

const html = document.querySelector("html");

function App() {
	const { theme } = useContext(AppData);
	html?.setAttribute("data-theme", theme);
	return (
		<>
			<Header />
			<Main />
			<Footer />
		</>
	);
}

export default App;
