import ITextData from "../interfaces/IText-data";

const textData: ITextData = {
  title: {
    ru: "Проекты",
    en: "Projects",
  },
  button: {
    ru: "все проекты",
    en: "see all projects",
  },
};

export default textData;
