import ITextData from "../interfaces/IText-data";

const textData: ITextData = {
  name: {
    ru: "Юрий Никитин",
    en: "Iurii Nikitin",
  },
  button: {
    ru: "Скачать Резюме",
    en: "Download Resume",
  },
};

export default textData;
