import { useContext, useMemo } from "react";
import { AppData } from "../app-provider/App-provider";
import { useForm, SubmitHandler } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import Notify from "../notify/Notify";
import FormField from "../contact-form-field/Contact-form-field";
import TInputs from "../../interfaces/TInputs";
import inputs from "../../data/inputs-data";

import textData, { errorsData } from "../../data/contact-form-text-data";

import Spinner from "../spinner/Spinner";

import "./contact-form.sass";

const phpMailer = process.env.REACT_APP_PHPMAILER_URL as string;

function ContactForm() {
	const { lang, theme, isSubmitting, setIsSubmitting } = useContext(AppData);
	const notify = useMemo(() => Notify(theme), [theme]);

	const {
		register,
		handleSubmit,
		watch,
		reset,
		setValue,
		formState: { errors },
	} = useForm<TInputs>({});


	useFormPersist("formValues", {
		watch,
		setValue,
	});

	function resetForm() {
		reset();
		sessionStorage.removeItem("formValues");
	}

	const onSubmit: SubmitHandler<TInputs> = (data) => {
		const timer = setTimeout(() => {
			setIsSubmitting(true);
		}, 700);
		fetch(phpMailer, {
			method: "POST",
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.result === "success") {
					notify.success(response.info[lang]);
					resetForm();
				} else {
					notify.error(response.info[lang]);
					console.error(response.desc);
				}
			})
			.catch((error) => {
				notify.error(errorsData.fetch.error[lang]);
				console.error(error);
			})
			.finally(() => {
				clearTimeout(timer);
				setIsSubmitting(false);
			});
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="contact-form contact-form_main"
			data-theme={theme}
		>
			{inputs.map((field, i) => (
				<FormField
					name={field.name}
					register={register}
					errors={errors}
					validation={field.validation}
					key={i}
				/>
			))}

			<button
				className="button button_submit"
				data-theme={theme}
				disabled={isSubmitting}
			>
				{isSubmitting ? <Spinner /> : textData.button[lang]}
			</button>
		</form>
	);
}

export default ContactForm;
