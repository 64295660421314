import IProjectItem from "../../interfaces/IProject-item";
import { useContext } from "react";
import ProjectItemSlider from "../project-item-slider/Project-item-slider";
import ProjectItemTechnologies from "../project-item-technologies/Project-item-technologies";
import { AppData } from "../app-provider/App-provider";

import 'simplebar-react/dist/simplebar.min.css';
import "./project-item.sass";

import GithubIcon from "../../icons/github-icon";
import LinkIcon from "../../icons/link-icon";

function ProjectItem({ imgs, title, technologies, description, github, link }: IProjectItem) {

	const { lang, theme } = useContext(AppData);

	return (
		<div className="project-item" data-theme={theme}>
			<div className="project-item__header">
				<ProjectItemSlider imgs={imgs}/>
			</div>
			<div className="project-item__wrapper">
				<h3 className="project-item__title">{title}</h3>
				<ProjectItemTechnologies technologies={technologies}/>
				<div className="project-item__descr">{description[lang]}</div>
				<div className="project-item__links">
					{github ? (
						<a
							className="project-item__github"
							href={github}
							rel="noreferrer"
							target="_blank"
						>
							<button className="button button_link" data-theme={theme}>
								GitHub
								<GithubIcon height="60%" />
							</button>
						</a>
					) : null}
					{link ? (
						<a
							className="project-item__link"
							href={link}
							rel="noreferrer"
							target="_blank"
						>
							<button className="button button_link" data-theme={theme}>
								Visit
								<LinkIcon height="55%" />
							</button>
						</a>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default ProjectItem;
