function LinkIcon(props: { height: string }) {
  return (
    <svg
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.98 20"
    >
      <path
        d="m18.41,9.07l-3.76,3.76c-2.08,2.08-5.44,2.08-7.5,0-.32-.32-.58-.69-.8-1.07l1.74-1.74c.08-.08.19-.13.29-.19.13.42.34.8.66,1.12,1.04,1.04,2.72,1.04,3.76,0l3.76-3.76c1.04-1.04,1.04-2.72,0-3.76-1.04-1.04-2.72-1.04-3.76,0l-1.33,1.34c-1.09-.42-2.25-.53-3.39-.37l2.85-2.85c2.08-2.08,5.44-2.08,7.5,0,2.08,2.08,2.08,5.44,0,7.5l-.02.02Zm-9.91,6.17l-1.33,1.34c-1.04,1.04-2.72,1.04-3.76,0s-1.04-2.72,0-3.76l3.76-3.76c1.04-1.04,2.72-1.04,3.76,0,.32.32.53.72.66,1.12.1-.06.19-.1.29-.19l1.74-1.74c-.22-.38-.48-.75-.8-1.07-2.08-2.08-5.44-2.08-7.5,0l-3.76,3.76c-2.08,2.08-2.08,5.44,0,7.5,2.08,2.08,5.44,2.08,7.5,0l2.85-2.85c-1.14.16-2.3.05-3.39-.37l-.02.02Z"
        fill="#dbffff"
      />
    </svg>
  );
}

export default LinkIcon;
