import { toast, cssTransition, ToastOptions } from "react-toastify";
import { Theme } from "../../interfaces/IApp-data";

function Notify(theme: Theme) {
	const slide = cssTransition({
		enter: "animate__animated animate__slideInUp",
		exit: "animate__animated animate__slideOutDown",
	});

	return {
		settings: {
			position: "bottom-center",
			hideProgressBar: true,
			draggable: true,
			transition: slide,
			theme: theme,
		} as ToastOptions,
		success: function (msg: string | JSX.Element) {
			toast.success(msg, {
				toastId: "success",
				...(this.settings as ToastOptions),
			});
		},
		error: function (msg: string | JSX.Element) {
			toast.error(msg, {
				toastId: "error",
				...(this.settings as ToastOptions),
			});
		},
	};
}

export default Notify;
