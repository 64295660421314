import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {

	const [isloading, setIsLoading] = useState(false);
	const { pathname } = useLocation();

	useEffect(() => {
		isloading ? window.scrollTo(0, 0) : setIsLoading(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return null;
}