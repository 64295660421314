import ITextData from "../interfaces/IText-data";
import IErrorsData from "../interfaces/IErrors-data";

const textData: ITextData = {
  name: {
    ru: "Имя",
    en: "Name",
  },
  email: {
    ru: "Email",
    en: "Email",
  },
  message: {
    ru: "Сообщение",
    en: "Message",
  },
  namePlaceholder: {
    ru: "Ваше имя",
    en: "Your name",
  },
  emailPlaceholder: {
    ru: "Ваш email",
    en: "Your email",
  },
  messagePlaceholder: {
    ru: "Ваше сообщение",
    en: "Your message",
  },
  button: {
    ru: "Отправить",
    en: "Submit",
  },
};

export const errorsData:IErrorsData = {
  name: {
    required: {
      ru: "Это поле является обязательным",
      en: "This field is required",
    },
    minLength:{
      ru: "Имя слишком короткое",
      en: "Name is too short",
    },
    pattern: {
      ru: "Неправильное имя",
      en: "Invalid name",
    },
  },
  email: {
    required: {
      ru: "Это поле является обязательным",
      en: "This field is required",
    },
    pattern: {
      ru: "Неправильный email",
      en: "Invalid email",
    },
  },
  fetch: {
    error: {
      ru: "Сообщение не отправлено",
      en: "Message not sent",
    }
  }
}

export default textData;
