import { createContext, useMemo } from "react";
import useStorage from "../../hooks/Use-storage";
import useHttp from "../../hooks/Use-http";
import query from "../../graphql/query";
import transformResponse from "../../utils/transform-response";

import IAppData from "../../interfaces/IApp-data";

const apiUrl = process.env.REACT_APP_API_URL as string;

export const AppData = createContext<IAppData>({
	lang: "en",
	theme: "light",
	isSubmitting: false,
	data: null,
	responseStatus: "idle",
	responseError: null,
	setTheme: () => {},
	setLang: () => {},
	setIsSubmitting: () => {},
});



function getSystemTheme() {
	return window.matchMedia("(prefers-color-scheme:dark)").matches
		? "dark"
		: "light";
}

const AppProvider = (props: { children: React.ReactNode; }): React.ReactElement => {


	const { data, status, error } = useHttp(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: query
    }),
  });

		const [theme, setTheme] = useStorage({
			key: "theme",
			initialValue: getSystemTheme(),
			storage: "localStorage",
			possibleValues: ["dark", "light"],
		});

	const [lang, setLang] = useStorage({
		key: "lang",
		initialValue: "en",
		storage: "localStorage",
		possibleValues: ["en", "ru"],
	});

	const [isSubmitting, setIsSubmitting] = useStorage({
		key: "isSubmiting",
		initialValue: false,
		storage: "sessionStorage",
	});

	window
		.matchMedia("(prefers-color-scheme: dark)")
		.addEventListener("change", () => {
			setTheme(getSystemTheme());
		});

		const transformedData = useMemo(() => transformResponse(data), [data]);

	const appData: IAppData = {
		theme,
		lang,
		isSubmitting,
		data:transformedData,
		responseStatus: status,
		responseError: error,
		setTheme,
		setLang,
		setIsSubmitting,
	} as IAppData;

	return (
		<AppData.Provider value={appData}>
				{props.children}
		</AppData.Provider>
	);
};

export default AppProvider;
