import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";
import textData from "../../data/skill-item-list-text-data";
import SkillItem from "../skill-item/Skill-item";
import ItemsList from "../items-list/Items-list";
import ItemsListWrapper from "../items-list-wrapper/Items-list-wrapper";
import SkillItemSkeleton from "../skeletons/skill-item-skeleton/Skill-item-skeleton";

import "./skill-item-list.sass";

function SkillItemList() {

	const {data, responseStatus} = useContext(AppData);

	let content;

	switch(responseStatus){
		case "loading":
			const skeletonItems = [];
      for (let i = 0; i < 14; i++) {
        skeletonItems.push(<SkillItemSkeleton key={i} />);
      }
      content = skeletonItems;
			break;
		case "success":
			content = <ItemsList
				data={data?.skillItems || null}
				Item={SkillItem}
			/>
			break;
	}


	return (
		<ItemsListWrapper clazz="skill" title={textData.title}>
		{content}
		</ItemsListWrapper>
	);
}

export default SkillItemList;