import TextSkeleton from "../text-skeleton/Text-skeleton";
import "./skill-item-skeleton.sass";

function SkillItemSkeleton() {
	return (
		<div className="skill-item-skeleton">
			<div className="skill-item-skeleton__logo"></div>
			<TextSkeleton rows={1} margin={0}/>
		</div>
	)
}

export default SkillItemSkeleton;