const query = `{
  promoText{
    data{
      attributes{
        value{
          ru
          en
        }
      }
    }
  }
  aboutMeText{
    data{
      attributes{
        value{
          ru
          en
        }
      }
    }
  }
projectItems(pagination:{pageSize:50}){
  data{
    attributes{
      title
      description{
        ru
        en
  },
      technologies{
        value
},
      github
      link
      imgs{
        data{
          attributes{
            url
          }
        }
      }
    }
  }
}
  skillItems(pagination:{pageSize:50}){
    data{
      attributes{
        name
        icon{
          data{
            attributes{
              url
            }
          }
        }
      }
    }
  }
  secretMessage {
  data{
    attributes{
      value{
        ru
        en
      }
    }
  }
}
}`

export default query;