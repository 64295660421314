import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";
import textData from "../../data/promo-text-data";

import TextSkeleton from "../skeletons/text-skeleton/Text-skeleton";

import "./promo.sass";

import hero_light from "../../img/hero_light.svg";
import hero_dark from "../../img/hero_dark.svg";

const resume = process.env.REACT_APP_RESUME_URL;

function Promo() {
	const { lang, theme, data, responseStatus } = useContext(AppData);

	let promoDescr;

	switch(responseStatus){
		case "loading":
			promoDescr = <TextSkeleton rows={3}/>
			break;
		case "success":
			promoDescr = data?.promoText[lang];
			break;
	}

	return (
		<section className="promo" data-theme={theme}>
			<div className="container">
				<div className="promo__wrapper">
					<div className="promo__text">
						<h2 className="promo__subheader">Developer</h2>
						<h1 className="promo__header">{textData.name[lang]}</h1>
						<div className="promo__descr">{promoDescr}</div>
						<a
							className="promo__link"
							target="_blank"
							rel="noreferrer"
							href={resume}
							download
						>
							<button className="button button_promo" data-theme={theme}>
								{textData.button[lang]}
							</button>
						</a>
					</div>
					<div className="promo__img-wrapper">
						<img
							className="promo__img light"
							src={hero_light}
							alt="hero"
							width="583px"
							height="583px"
							style={
								{
									transitionDelay: theme === "light" ? ".5s" : "0s"
								}
							}
						/>
						<img
							className="promo__img dark"
							src={hero_dark}
							alt="hero"
							width="583px"
							height="583px"
							style={
								{
									transitionDelay: theme === "dark" ? ".5s" : "0s"
								}
							}
						/>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Promo;
