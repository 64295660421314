import { MouseEventHandler, useRef } from "react";
import Slider from "react-slick";
import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./project-item-slider.sass";

function SampleArrow(props:{className: string, onClick: MouseEventHandler}) {
	return (
		<div
			className={props.className}
			onClick={props.onClick}
		/>
	);
}

function ProjectItemSlider({imgs}:{imgs:string[]}) {

	const { theme } = useContext(AppData);
	let sliderRef = useRef<Slider>(null);

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

	const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
		nextArrow: <SampleArrow className="slick-arrow slick-prev" onClick={previous}/>,
    prevArrow: <SampleArrow className="slick-arrow slick-next" onClick={next} />
  };
	
	return (
		<Slider className={theme} ref={sliderRef} {...sliderSettings}>
			{imgs.map((img, i) => (
			<div key={i}>
				<img
					width="520px"
					height="386px"
					className="slick-slider__img"
					src={img}
					alt="project preview"
				/>
			</div>))}
		</Slider>
	)
}

export default ProjectItemSlider;