import { MouseEventHandler } from "react";
import { useContext } from "react";
import { AppData } from "../app-provider/App-provider";

import "./hamburger.sass";

function Hamburger(props: {
	isActive: boolean;
	toggleMenu: MouseEventHandler;
}) {
	const { isActive, toggleMenu } = props;
	const { theme } = useContext(AppData);
	let clazz: string = "hamburger";
	if (isActive) {
		clazz += " hamburger_active";
	}

	return (
		<div onClick={toggleMenu} className={clazz} data-theme={theme}>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
}

export default Hamburger;
