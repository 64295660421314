import { useContext } from "react";
import { AppData} from "../app-provider/App-provider";

import textData, { errorsData } from "../../data/contact-form-text-data";
import IFieldProps from "../../interfaces/IField-props";

function FieldError({ name, error }: { name: string; error: string }) {
		const { lang } = useContext(AppData);

		return error && errorsData[name] ? (
				<div className="error">{errorsData[name][error][lang]}</div>
		) : null;
}

function Input({ name, register, validation }: IFieldProps) {

		const { lang, isSubmitting } = useContext(AppData);

		const inputProps = {
				...register(name, validation),
				placeholder: textData[name + "Placeholder"][lang],
				disabled: isSubmitting,
		};

		return name === "message" ? (
				<textarea {...inputProps} />
		) : (
				<input {...inputProps} />
		);
}

function FormField({ name, register, validation, errors }: IFieldProps) {
		const { lang, isSubmitting } = useContext(AppData);

		return (
				<div className="contact-form__field" data-disabled={isSubmitting}>
						<div className="descr">{textData[name][lang]}</div>
						<Input name={name} register={register} validation={validation} />
						<FieldError name={name} error={errors?.[name]?.type ?? ""} />
				</div>
		);
}

export default FormField;
